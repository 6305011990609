<template>
	<div class="ybd-view">
		<designHeader :isSaving="isSaving" :showBtn="showBtn" :hasEdit="hasEdit" :isJz="isJz"></designHeader>
		<div class="container">
			<designLeftNav :fromType='fromType'></designLeftNav>
			<div class="main">
				<div class="set-list">
					<el-tabs v-model="activeName">
						<el-tab-pane label="答题限制" name="answerLimit">
							<div class="set-item answer-limit">
								<el-scrollbar>
									<div class="part">
										<p class="title">链接分享</p>
										<div class="list">
											<div class="item">
												<div class="head">
													<div class="label">
														<span class="txt">每台电脑或手机答题次数限制</span>
														<el-tooltip placement="right" effect="light" popper-class="cus-pop">
															<div slot="content">根据答题者浏览器cookie判断，如用户清空cookie或更换浏览<br/>器则限制失效。</div>
															<el-button icon="el-icon-info" circle></el-button>
														</el-tooltip>
													</div>
													<div>
														<el-switch v-model="pcPhone" :active-value="1" :inactive-value="0" active-color="#0cf" inactive-color="#d8d8d8"></el-switch>
													</div>
												</div>
												<div class="body" v-show="pcPhone">
													<el-select v-model="pcPhoneCondition" placeholder="请选择">
														<el-option
															v-for="item in limitUnit"
															:key="item.value"
															:label="item.label"
															:value="item.value">
														</el-option>
													</el-select>
													<span>答题</span>
													<el-input :class="{'error': pcPhoneCount == ''}" v-model="pcPhoneCount" type="number" min="1" oninput="if(value.length > 3)value=value.slice(0,3)"></el-input>
													<span>次</span>
												</div>
											</div>
											<div class="item">
												<div class="head">
													<div class="label">
														<span class="txt">每个IP答题次数限制</span>
														<el-tooltip placement="right" effect="light" popper-class="cus-pop">
															<div slot="content">根据用户IP地址判断，同一网络环境下IP地址相同，学校、单位<br/>内部调研慎选。用户不使用Wi-Fi，切换成4G网络，则限制会失<br/>效。</div>
															<el-button icon="el-icon-info" circle></el-button>
														</el-tooltip>
													</div>
													<div>
														<el-switch v-model="ip" :active-value="1" :inactive-value="0" active-color="#0cf" inactive-color="#d8d8d8"></el-switch>
													</div>
												</div>
												<div class="body" v-show="ip">
													<el-select v-model="ipCondition" placeholder="请选择">
														<el-option
															v-for="item in limitUnit"
															:key="item.value"
															:label="item.label"
															:value="item.value">
														</el-option>
													</el-select>
													<span>答题</span>
													<el-input :class="{'error': ipCount == ''}" v-model="ipCount" type="number" min="1" oninput="if(value.length > 3)value=value.slice(0,3)"></el-input>
													<span>次</span>
												</div>
											</div>
										</div>
									</div>
									<div class="part">
										<p class="title">答题限制</p>
										<div class="list">
											<div class="item">
												<div class="head">
													<div class="label">
														<span class="txt">启动访问密码</span>
													</div>
													<div>
														<el-switch v-model="startPassword" :active-value="1" :inactive-value="0" active-color="#0cf" inactive-color="#d8d8d8"></el-switch>
													</div>
												</div>
												<div class="body" v-show="startPassword">
													<el-input v-model="password" placeholder="请输入访问密码"></el-input>
												</div>
											</div>
											<div class="item">
												<div class="head">
													<div class="label">
														<span class="txt">只有邮件邀请的受访者可回答</span>
														<el-tooltip placement="right" effect="light" popper-class="cus-pop">
															<div slot="content">只能通过邮件打开收到的链接，参与答题后</div>
															<el-button icon="el-icon-info" circle></el-button>
														</el-tooltip>
													</div>
													<div>
														<el-switch v-model="emailInvite" :active-value="1" :inactive-value="0" active-color="#0cf" inactive-color="#d8d8d8"></el-switch>
													</div>
												</div>
											</div>
											<div class="item">
												<div class="head">
													<div class="label">
														<span class="txt">设置开始/结束时间</span>
													</div>
													<div>
														<el-switch v-model="setTime" :active-value="1" :inactive-value="0" active-color="#0cf" inactive-color="#d8d8d8"></el-switch>
													</div>
												</div>
												<div class="body" v-show="setTime">
													<el-date-picker v-model="timeArr" type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" align="center" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
												</div>
											</div>
											<div class="item">
												<div class="head">
													<div class="label">
														<span class="txt">答题邮件提醒</span>
													</div>
													<div>
														<el-switch v-model="isEmail" :active-value="1" :inactive-value="0" active-color="#0cf" inactive-color="#d8d8d8"></el-switch>
													</div>
												</div>
												<div class="body" v-show="isEmail">
													<el-input v-model="email" placeholder="请输入邮箱地址"></el-input>
												</div>
											</div>
										</div>
									</div>
								</el-scrollbar>
								<div class="submit-btn">
									<el-button type="primary" @click="updataFormSetData">保存</el-button>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="质量控制" name="qualityControl">
							<div class="set-item quality-control">
								<el-scrollbar>
									<div class="part">
										<p class="title">答题质量</p>
										<div class="list">
											<div class="item">
												<div class="head">
													<div class="label">
														<span class="txt">设定答题数据上限</span>
													</div>
													<div>
														<el-switch v-model="answerAstrict" :active-value="1" :inactive-value="0" active-color="#0cf" inactive-color="#d8d8d8"></el-switch>
													</div>
												</div>
												<div class="body" v-show="answerAstrict">
													<span>收集</span>
													<el-input v-model="answerCount" type="number" min="1" oninput="if(value.length > 3)value=value.slice(0,3)"></el-input>
													<span>份数据时结束</span>
												</div>
											</div>
											<div class="item special">
												<router-link to='/invalidAnswer' class="head">
													<div class="label">
														<span class="txt">无效答卷筛选</span>
													</div>
													<div>
														<span class="el-icon-arrow-right"></span>
													</div>
												</router-link>
											</div>
										</div>
									</div>
								</el-scrollbar>
								<div class="submit-btn">
									<el-button type="primary" @click="updataFormSetData">保存</el-button>
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { get,post } from "/src/services/ajax_ucenter.js";
import designHeader from "@/components/designHeader.vue";
import designLeftNav from "@/components/designLeftNav.vue";
export default {
	components: {
		designHeader,
		designLeftNav,
	},
	data(){
		return {
			activeName: 'answerLimit',
			formId: sessionStorage.getItem('formId')?sessionStorage.getItem('formId'):'',
			fromType:'',
			showBtn:false,
			hasEdit:0,
			isSaving:false,
			isJz: sessionStorage.getItem('isJz')?true:false,
			wechat: 0,
			wechatCondition: 'only',
			wechatString: '只能',
			wechatCount: 1,
			pcPhone: 0,
			pcPhoneCondition: 'only',
			pcPhoneString: '只能',
			pcPhoneCount: 1,
			ip: 0,
			ipCondition: 'only',
			ipString: '只能',
			ipCount: 1,
			startPassword: 0,
			password: '111111',
			emailInvite: 0,
			setTime: 0,
			isEmail: 0,
			email: '',
			answerAstrict: 0,
			answerCount: '',
			timeArr: [],
			id:"",
			limitUnit: [
				{
					label: '只能',
					value: 'only'
				},
				{
					label: '每小时',
					value: 'hour'
				},
				{
					label: '每天',
					value: 'day'
				},
				{
					label: '每自然周',
					value: 'week'
				},
				{
					label: '每7天内',
					value: '7_days'
				},
				{
					label: '每自然月',
					value: 'month'
				},
				{
					label: '每30天内',
					value: '30_days'
				},
				{
					label: '每季度',
					value: 'quarter'
				},
				{
					label: '每90天内',
					value: '90_days'
				},
				{
					label: '每自然年',
					value: 'year'
				},
				{
					label: '每365天',
					value: '365_days'
				}
			]			
		}
	},
	mounted(){
		this.fromType = this.$route.query.fromType?this.$route.query.fromType:'';
		this.formTypeId = this.$route.query.id?this.$route.query.id:sessionStorage.getItem('formTypeId')?sessionStorage.getItem('formTypeId'):'';
		this.formId = this.$route.query.formId?this.$route.query.formId:sessionStorage.getItem('formId')?sessionStorage.getItem('formId'):'';
		if(this.formTypeId){
			sessionStorage.setItem('formTypeId',this.formTypeId)
		}
		if(this.formId){
			sessionStorage.setItem('formId',this.formId)
			this.getFormSetData(this.formId);
		}
	},
	methods: {
		// 获取模板表单数据
		getFormSetData(formId){
			const _this = this;
			get('/formadmin/astrict_control/astrict_control.jhtml',{formId}).then(res => {
				if(res.code == 200){
					const data = res.data.astrictControl?res.data.astrictControl:{};
					_this.wechat = data.wechat?data.wechat:0;
					_this.wechatCondition = data.wechatCondition?data.wechatCondition:'only';
					_this.wechatString = data.wechatString?data.wechatString:'只能';
					_this.wechatCount = data.wechatCount?data.wechatCount:1;
					_this.pcPhone = data.pcPhone?data.pcPhone:0;
					_this.pcPhoneCondition = data.pcPhoneCondition?data.pcPhoneCondition:'only';
					_this.pcPhoneString = data.pcPhoneString?data.pcPhoneString:'只能';
					_this.pcPhoneCount = data.pcPhoneCount?data.pcPhoneCount:1;
					_this.ip = data.ip?data.ip:0;
					_this.ipCondition = data.ipCondition?data.ipCondition:'only';
					_this.ipString = data.ipString?data.ipString:'只能';
					_this.ipCount = data.ipCount?data.ipCount:1;
					_this.startPassword = data.startPassword?data.startPassword:0;
					_this.password = data.password?data.password:'111111';
					_this.emailInvite = data.emailInvite?data.emailInvite:0;
					_this.setTime = data.setTime?data.setTime:0;
					_this.timeArr = data.start_time && data.end_time?[data.start_time,data.end_time]:[];
					_this.isEmail = data.isEmail?data.isEmail:0;
					_this.email = data.email?data.email:'';
					_this.answerAstrict = data.answerAstrict?data.answerAstrict:0;
					_this.answerCount = data.answerCount?data.answerCount:'';
					if(data._id){
						_this.id = data._id;
					}
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 提交模板表单数据
		updataFormSetData(){
			const _this = this;
			let jsonDate = {
				formId: _this.formId,
				wechat: 0,
				wechatCondition: "only",
				wechatString: "只能",
				wechatCount: 1,
				_id: _this.id

			};
			if(_this.pcPhone == 1){
				if(_this.pcPhoneCount == ''){
					_this.$message.error("请输入答题次数！");
					return false;
				}
			}
			jsonDate = {
				...jsonDate,
				pcPhone: _this.pcPhone?1:0,
				pcPhoneCount: _this.pcPhoneCount,
				pcPhoneCondition: _this.pcPhoneCondition,
				pcPhoneString: _this.limitUnit.filter(i=>i.value == _this.pcPhoneCondition)[0].label,
			}
			if(_this.ip == 1){
				if(_this.ipCount == ''){
					_this.$message.error("请输入答题次数！");
					return false;
				}
			}
			jsonDate = {
				...jsonDate,
				ip: _this.ip?1:0,
				ipCount: _this.ipCount,
				ipCondition: _this.ipCondition,
				ipString: _this.limitUnit.filter(i=>i.value == _this.ipCondition)[0].label,
			}
			if(_this.startPassword == 1){
				if(_this.password == ''){
					_this.$message.error("请输入访问密码！");
					return false;
				}
			}
			jsonDate = {
				...jsonDate,
				startPassword: _this.startPassword?1:0,
				password: _this.password,
				emailInvite: _this.emailInvite?1:0,
			}
			if(_this.setTime == 1){
				if(_this.timeArr == ''){
					_this.$message.error("请选择时间！");
					return false;
				}
			}
			jsonDate = {
				...jsonDate,
				setTime: _this.setTime?1:0,
				start_time: _this.timeArr[0],
				end_time: _this.timeArr[1],
			}
			if(_this.isEmail == 1){
				if(_this.email == ''){
					_this.$message.error("请输入邮箱地址！");
					return false;
				}
			}
			jsonDate = {
				...jsonDate,
				isEmail: _this.isEmail?1:0,
				email: _this.email,
			}
			if(_this.answerAstrict == 1){
				if(_this.answerCount == ''){
					_this.$message.error("请输入答题数据上限！");
					return false;
				}
			}
			jsonDate = {
				...jsonDate,
				answerAstrict: _this.answerAstrict?1:0,
				answerCount: _this.answerCount,
			}
			post('/formadmin/astrict_control/save_astrictControl.jhtml', {jsonDate:JSON.stringify(jsonDate)}).then(res => {
				if(res.code == 200){
					_this.$message.success("保存成功！");
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
	}
};
</script>
<style lang="less" scoped>
	.set-list{
		width: 1100px;
		height: 100%;
		padding: 40px 0 56px;
		margin: 0 auto;
		::v-deep .el-tabs{
			display: flex;
			flex-direction: column;
			height: 100%;
			.el-tabs__header{
				margin: 0;
				.el-tabs__nav-wrap{
					.el-tabs__nav-scroll{
						.el-tabs__nav{
							display: flex;
							.el-tabs__active-bar{
								display: none;
							}
							.el-tabs__item{
								display: flex;
								align-items: center;
								justify-content: center;
								width: 186px;
								height: 54px;
								padding: 0;
								font-size: 16px;
								&:hover{
									color: var(--theme-color);
								}
							}
							.is-active{
								background-color: #fff;
								color: var(--theme-color);
								border: 1px solid #eee;
								border-bottom: none;
							}
						}
					}
					&:after{
						display: none;
					}
				}
			}
			.el-tabs__content{
				flex: 1;
				background-color: #fff;
				border: 1px solid #eee;
				.el-tab-pane{
					height: 100%;
					.set-item{
						height: 100%;
						.el-scrollbar{
							height: calc(100% - 100px);
							.el-scrollbar__wrap{
								height: 100%;
								overflow-x: hidden;
							}
						}
						.submit-btn{
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 20px 0 30px;
							.el-button{
								width: 100px;
							}
						}
					}
					.el-scrollbar__view{
						padding: 16px 60px 0;
						.part{
							padding: 0 0 15px;
							border-bottom: 1px solid #eee;
							.title{
								padding: 25px 0;
								font-size: 16px;
							}
							.list{
								padding: 0 15px;
								.item{
									.head{
										display: flex;
										align-items: center;
										justify-content: space-between;
										height: 38px;
										padding: 0 125px 0 0;
										font-size: 13px;
										color: #707070;
										.label{
											display: flex;
											align-items: center;
											.el-button.is-circle{
												padding: 0;
												border: none;
												margin: 0 0 0 10px;
												font-size: 18px;
												color: #bbb;
											}
										}											
									}
									.body{
										display: flex;
										align-items: center;
										height: 43px;
										padding: 0 20px;
										margin: 7px 0 11px;
										background-color: #f7f7f7;
										span{
											margin: 0 5px;
										}
										.el-select{
											width: 104px;
										}
										.el-input{
											width: auto;
										}
										.error{
											.el-input__inner{
												border-color: #f00;
											}
										}
										.el-date-editor{
											.el-range-input{
												background-color: transparent;
											}
											.el-input__icon{
												line-height: 18px;
											}
											.el-range-separator{
												line-height: 1;
												font-size: 12px;
												color: #666;
											}
										}
										.el-input__inner{
											height: 24px;
											line-height: 22px;
											padding-left: 6px;
											border-radius: 0;
											background-color: transparent;
										}
										input[type=number]{
											width: 60px;
										}
										.el-input__icon{
											line-height: 22px;
										}
									}
								}
								.special{
									cursor: pointer;
									&:hover{
										.head{
											color: var(--theme-color);
										}
									}
								}
							}
							&:last-child{
								padding: 0;
								border: none;
							}
						}
					}
				}
			}
		}
	}
</style>